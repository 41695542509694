@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?d5jrns');
  src:  url('fonts/icomoon.eot?d5jrns#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?d5jrns') format('truetype'),
    url('fonts/icomoon.woff?d5jrns') format('woff'),
    url('fonts/icomoon.svg?d5jrns#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    position: relative;
    top: 1px;
    display: inline-block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-1 {
    font-size: 1.5em !important;
}

.icon-2 {
    font-size: 1.8em !important;
}

.icon-9 {
    font-size: 9px !important;
}

.icon-12 {
    font-size: 12px !important;
}

.icon-14 {
    font-size: 14px !important;
}

.icon-16 {
    font-size: 16px !important;
}

.icon-24 {
    font-size: 24px !important;
}

.icon-32 {
    font-size: 32px !important;
}

.icon-40 {
    font-size: 40px !important;
}

.icon-48 {
    font-size: 48px !important;
}

.icon-kpi:before {
  content: "\e90b";
}
.icon-info-turnover:before {
  content: "\e90c";
}
.icon-protocol:before {
  content: "\e90d";
}
.icon-contacts:before {
  content: "\e90e";
}
.icon-vehicle:before {
  content: "\e90f";
}
.icon-question:before {
  content: "\e910";
}
.icon-star:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e912";
}
.icon-up:before {
  content: "\e913";
}
.icon-exit:before {
  content: "\e914";
}
.icon-turnover:before {
  content: "\e915";
}
.icon-time-in:before {
  content: "\e916";
}
.icon-time-out:before {
  content: "\e917";
}
.icon-late:before {
  content: "\e918";
}
.icon-return-state:before {
  content: "\e919";
}
.icon-menu:before {
  content: "\e91a";
}
.icon-stat-monitor:before {
  content: "\e91b";
}
.icon-info:before {
  content: "\e91c";
}
.icon-checkbox:before {
  content: "\e900";
}
.icon-checkbox-empty:before {
  content: "\e901";
}
.icon-clear-filter:before {
  content: "\e902";
}
.icon-delete:before {
  content: "\e903";
}
.icon-file-download:before {
  content: "\e904";
}
.icon-file-upload:before {
  content: "\e905";
}
.icon-filter:before {
  content: "\e906";
}
.icon-filter-full:before {
  content: "\e907";
}
.icon-multi-checkbox:before {
  content: "\e908";
}
.icon-multi-checkbox-empty:before {
  content: "\e909";
}
.icon-multi-checkbox-some-outline:before {
  content: "\e90a";
}
