.table-fields-setting {
    overflow: auto;

    &_name {
        width: 100% !important;
        white-space: nowrap;
    }

    .table-loader {
        margin-top: 95px !important;
        height: calc(100vh - 300px) !important;
    }
}

.cell-field-name {
    font-weight: 500;
    color: #4d4d4d;
    display: inline;
}

.field-settings-table {
    border: 1px solid #ddd;
    height: calc(100vh - 175px) !important;
}
