.select-as-semantic {
    position: relative;

    .remove-select-button {
        position: absolute;
        background: white !important;
        right: 1px;
        top: 1px;
        bottom: 1px;
        margin: 0 !important;
        padding: 8px !important;
    }

    select {
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
        margin: 0 !important;
        outline: 0 !important;
        -webkit-appearance: none !important;
        tap-highlight-color: rgba(255, 255, 255, 0) !important;
        line-height: 1.21428571em !important;
        padding: 0.67857143em 1em !important;
        font-size: 1em !important;
        background: #fff !important;
        border: 1px solid rgba(34, 36, 38, 0.15) !important;
        color: rgba(0, 0, 0, 0.87) !important;
        border-radius: 0.28571429rem !important;
        -webkit-box-shadow: 0 0 0 0 transparent inset !important;
        box-shadow: 0 0 0 0 transparent inset !important;
        -webkit-transition: color 0.1s ease, border-color 0.1s ease !important;
        transition: color 0.1s ease, border-color 0.1s ease !important;
    }
}

.selected_dates {
    > div {
        width: 100%;
        display: flex;
    }


    &_text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
    }

    &_color {
        position: relative;

        &:before {
            position: absolute;
            left: 12px;
            content: ' ';
            height: 25px;
            width: 2px;
            background-color: #db2828;
            transform: rotate(45deg);
        }

        &:after {
            position: absolute;
            left: 12px;
            content: ' ';
            height: 25px;
            width: 2px;
            background-color: #db2828;
            transform: rotate(-45deg);
        }
    }
}

.ui.dropdown .menu > .item > .description,
.ui.dropdown > .text > .description {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ui.form .field > label {
    color: #4d4d4d !important;
    font-weight: 500 !important;
}

.label-disabled {
    color: #999 !important;
}

.label-warning-text {
    color: red;
    font-style: italic;
}

.react-datepicker-popper {
    width: max-content;
    z-index: 5 !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__input-container {
    width: 100% !important;
}

.react-datepicker__close-icon {
    &:after {
        padding: 0 !important;
        color: black !important;
        background: transparent !important;
        font-size: 17px !important;
        font-weight: bold !important;
    }
}

.input-error, .ui.input.error > input, .ui.input.error:hover > input {
    background-color: #fff6f6 !important;
    color: #9f3a38 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: #e0b4b4 1px solid !important;
}

.label-error {
    color: #9f3a38 !important;
    font-size: 0.8rem;
}

.label-info {
    color: #38AE24 !important;
    font-size: 16px;
    font-weight: 500;
}

.bool-radio-button {
    display: flex;
    height: 38px;
    align-items: center;

    .ui.radio.checkbox {
        padding-right: 15px;
    }
}

.radio-buttons {
    display: flex;
    justify-content: space-between;

    &_items {
        .ui.radio.checkbox {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.form-select {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;

    button {
        margin-left: 8px !important;
    }
    /* &_disabled {
         opacity: .8;
         background: rgba(1, 1, 1, 0.05);
     }*/
}

.ui.input:hover,
.react-datepicker-wrapper:hover {
    input {
        border-color: rgba(34, 36, 38, 0.35) !important;
        box-shadow: none;
    }
}

.ui.form textarea:hover {
    border-color: rgba(34, 36, 38, 0.35) !important;
    box-shadow: none;
}

.custom-checkbox {
    position: relative;

    input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    &_disabled {
        opacity: 0.4;
    }
}

input,
textarea {
    border: none !important;
    box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.15), 0 0 0 2px transparent !important;

    &:focus,
    .ui.selection.active.dropdown {
        box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    }

    &:hover:not(:focus) {
        box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.35), 0 0 0 2px transparent !important;
    }
}

.ui.selection.active.dropdown {
    border: none !important;
    box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
}

.ui.selection.active.dropdown .menu {
    box-shadow: none;
    border-color: rgba(34, 36, 38, 0.15) !important;
}

.return_cost_field {
    .row {
        align-items: flex-end !important;
    }
}

.field-hint {
    color: #bbc2c5;
    margin-bottom: 4px;
}

.inn-menu {
    width: 100% !important;
    min-width: 300px !important;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;

    &_items {
        min-width: 400px !important;
    }

    .item {
        overflow: hidden;
        white-space: break-spaces !important;

        &_header {
            color: #4d4d4d !important;
            margin-bottom: 5px;
        }

        &_text {
            color: #b3b3b3;
            font-size: 13px;
        }
    }
}

.inn-button {
    color: blue !important;
}

.loading-message {
    padding: 10px;

    &_loader {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}

.links-area {
    box-shadow: none !important;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    resize: vertical;
    overflow: auto;
    opacity: 0.8 !important;
    background: rgba(1, 1, 1, 0.05) !important;
    height: 78px;
    min-height: 36px;
    > div {
        cursor: pointer;
    }
}

.links-cell-value {
    white-space: break-spaces;
    resize: none;
    overflow-x: hidden;
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0) !important;
    width: 300px;
}

.popup-text {
    max-height: 80vh;
    overflow: auto;
}

.years {
    &__menu {
        padding-bottom: 20px !important;
    }

    &__btns {
        display: flex;
        justify-content: space-around;
        padding: 20px !important;
        border-bottom: 1px solid #e6e6e6;
        font-weight: 500;

        .icon {
            &:before {
                color: #135CA9;
            }
        }
    }

    &__select-block {
        max-width: 280px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        overflow: hidden;
        padding: 20px 20px 0 !important;

        &:last-child {
            padding: 0 !important;
        }
    }

    &__select-btn {
        background-color: white !important;
        margin-top: 10px !important;
        margin-bottom: 0 !important;
        color: #26A9CA !important;
        padding: 5px !important;
    }

    &__btn {
        padding: 6px 10px !important;
        margin: 4px 2px !important;
        background-color: white !important;

        &_end {
            border: 1px solid #034EA2 !important;
            background-color: #BCDBDE !important;
        }

        &_start {
            border: 1px solid #92E72B !important;
            background-color: #EAFEED !important;
        }
    }
}

#years__btn {
    border: 1px solid white;
    margin-left: 2px !important;
}

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:focus {
    border: 1px solid !important;
}

.select-dates {
    > div:first-child {
        display: flex;
        > div:first-child {
            margin-right: 10px;
        }
    }
    > div:last-child {
        max-width: 100% !important;
        > .button {
            padding-top: 0!important;
        }
    }
}

.dates-input {
    input {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .react-datepicker__day--keyboard-selected {
        background-color: #18a8cc !important;
    }
    .react-datepicker__day--highlighted {
        background-color: #034EA2 !important;
    }
    &.field.error input {
        border: #e0b4b4 1px solid !important;
        box-shadow: none !important;
    }
}

.code-area {
    --color-prettylights-syntax-sublimelinter-gutter-mark: #0008ff !important;
    --color-prettylights-syntax-entity-tag: #0008ff !important;
    > textarea {
        max-height: none !important;

    }
}