.required-fields-modal {
    .field {
        width: 100% !important;
        text-align: left;
        margin-right: 30% !important;
        margin-left: 30% !important;
        label {
            position: absolute;
            left: 0;
            width: 29%;
            text-align: right;
            line-height: 36px;
        }
        .form-select {
            width: 100%;
        }
    }
}