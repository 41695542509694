.banner {
    background: #0081C7 !important;
    border-radius: 0 !important;
    border: 1px solid #164194;

    &__close {
        position: absolute;
        top: 5px;
        right: 2px;
        cursor: pointer;
    }

    &__info {
        display: flex;
        align-items: center;

        .button {
            background-color: white !important;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
        }
    }

    &__btn {
        padding-left: 20px;
    }

    &__image {
        padding: 10px;
    }

    &__logo {
        min-width: 215px;
        height: 79px;
    }

    &__data {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__text {
        color: white;
        font-weight: 700;
        font-size: 20px;
    }

    &__header {
        font-size: 22px;
        padding-bottom: 20px;
    }
}

.banner-block {
    padding-bottom: 10px;
}