.centered-div {
    width: 509px;
    height: 160px;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
}

.login-form-wrapper {
    padding-top: 0 !important;
    width: 509px !important;
}

.login-form-wrapper .login-error {
    color: red;
}

.login-form-description {
    width: 277px !important;
    border-right: 1px solid #e0e1e2;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &-company {
        font-weight: bold;
        font-size: 18px;
    }

    img {
        position: absolute;
        left: 0;
        top: -16px;
    }
}

.login-form-input-wrapper {
    width: 232px !important;
}

.login-form-forgot-pass {
    cursor: pointer;
    font-size: 10.3pt;
    padding-top: 9px;
    padding-bottom: 9px;
    display: inline-block;
}

.language-switcher {
    margin-top: auto;
    margin-bottom: auto;
    width: 110px;
}

.login-bgn {
    background-image: url('../../img/bgn.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.login-wrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.login-segment {
    border-radius: 0 !important;
    width: 450px!important;
    padding-bottom: 25px !important;
}
.login-segment-transparent {
    background: rgba(0,0,0,0.3) !important;
    color: black;
}
.login-logo {
    text-align: center;
    padding-top: 5px;
}
.login-logo-transparent {
    background-color: white;
    margin: 15px;
    opacity: .9 !important;
    margin-top:30px;
    padding-top: 10px;
    padding-bottom: 5px;
}
.login-descr {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 5px;
    font-weight: bold;
    color: white;
}

.login-form {
    padding: 35px 15px 5px 15px;
}
.login-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-buttons {
    padding: 15px 15px 5px 15px;
    vertical-align: baseline;
}
.login-sign-up {
    color: white !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}
.login-links {
    padding-top:20px;
}
.login-links a {
    color:white !important;
}
.login-error {
    padding: 5px 10px 5px 10px;
    min-height: 31px;
    font-size: smaller;
    background: #db2828;
    color: white;
}

.login-header {
    display: flex !important;
    justify-content: end;
    padding: 15px 15px 0 15px !important;
}
