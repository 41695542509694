.personal-cabinet {
    display: flex;
    font-family: 'Ubuntu', sans-serif !important;

    .dimmer {
        z-index: 9 !important;
    }

    h1 {
        font-family: 'Ubuntu', sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        color: #4B5057;
        margin-bottom: 20px;
    }

    h2 {
        font-family: 'Ubuntu', sans-serif !important;
        font-weight: 400;
        font-size: 18px;
        color: #4B5057;
        text-transform: uppercase;
    }

    h3 {
        font-family: 'Ubuntu', sans-serif !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-transform: uppercase;
        color: #4B5057;

        > span {
            font-weight: 400;
            text-transform: lowercase;
        }
    }

    :not(.report__pagination).segment {
        padding: 0 !important;
    }

    &__popup {
        background-color: #3A84FF !important;
        border: 0 !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        color: white !important;
        border-radius: 10px !important;
        padding: 5px 10px !important;

        &:before {
            background-color: #3A84FF !important;
            box-shadow: none !important;
            margin-top: -0.34em !important;
        }

        &_info-icon {
            left: 15px !important;
            border: 1px solid #d8d8d8 !important;

            &:before {
                box-shadow: -1px -1px 0 0 #d8d8d8 !important;
            }
        }
    }

    &__menu {
        background: #034EA2 !important;
        width: 100px !important;
        margin-top: 60px !important;
        height: calc(100vh - 60px);
        position: fixed;
        z-index: 10;

        .active.item, .item:hover {
            background: rgba(255, 255, 255, .2) !important;
        }

        > .item {
            &:first-child {
                margin-top: 30px;
            }

            margin: 20px auto;
            min-width: 36px;
            width: 36px;
            min-height: 36px;
            height: 36px;
            padding: 4px !important;
            border-radius: 8px !important;

            > .icon {
                margin-top: 1px !important;

                &::before {

                }

            }

            &:before {
                background: rgba(0, 0, 0, 0) !important;
            }
        }
    }

    &__page {
        width: calc(100% - 100px);
        height: calc(100vh - 60px);
        overflow: auto;
        margin-left: auto;
        padding-top: 10px !important;
        margin-top: 60px;

        .card-content {
            padding-top: 70px !important;
        }

        .card-header-panel, .card-actions-panel {
            width: calc(100% - 100px);
        }

        .container {
            padding-top: 0 !important;
        }

        &_home {
            display: flex;
            justify-content: center;

            .ui.grid {
                height: 100%;
            }

            input {
                height: 40px;
                box-shadow: inset 0 0 0 1px #DEE5EC, 0 0 0 2px transparent !important;
            }

            .row {
                > .personal-cabinet__block {
                    &:first-child {
                        max-width: 744px !important;
                    }

                    &:last-child {
                        max-width: 596px !important;
                    }
                }

                @media screen and (min-width: 1500px) {
                    > .personal-cabinet__block {
                        &:last-child {
                            min-width: calc(100vw - 200px - 744px) !important;
                        }
                    }
                }
            }
        }
    }

    &__form {
        height: auto;
        overflow: inherit;
    }

    &__block {
        padding: 12px 26px !important;

        .ui.inverted.dimmer {
            background-color: rgba(245, 247, 250, 0.61) !important;
        }

        &:first-child {
            padding-right: 18px !important;
        }

        &:last-child {
            padding-left: 0 !important;
        }
    }

    &__table {
        &_header {
            text-align: center;
        }
    }

    &__tc-name {
        background-color: #f9fafb;
    }

    &__btn-plus {
        padding-right: 15px;
        cursor: pointer;
    }
}

.turnover {
    &__header {
        td:not(:first-child) {
            background-color: #f9fafb;
        }
    }

    tr:not(.turnover__header) {
        td:first-child {
            background-color: #f9fafb;
        }
    }

    &:not(:first-child) {
        border-radius: 0 !important;
        border-top: 1px solid rgba(34, 36, 38, .15) !important;
    }

    &:not(:last-child) tr:last-child td {
        border-bottom: 1px solid rgba(34, 36, 38, .15) !important;
    }
}

.links-block {

    &.violet {
        .links-block__icon {
            background-color: #c8bfe7 !important;
            border-color: #c8bfe7;
        }

        .links-block__links {
            border-color: #c8bfe7;
        }
    }

    &.yellow {
        .links-block__icon {
            background-color: #efe4b0 !important;
            border-color: #efe4b0;
        }

        .links-block__links {
            border-color: #efe4b0;
        }
    }

    display: flex;

    &__data {
        border: 1px solid;
        padding: 20px;
    }

    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 32px;
    }

    &__links {
        background-color: #f9f9f9 !important;
        border-left: 0;
        font-weight: bold;
        text-decoration: underline;

        a {
            color: black !important;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

.field-comments {
    .dictionary-edit > .field {
        min-width: 50% !important;
    }
}

.meeting-protocol {
    &__content {
        .row:first-child {
            .dictionary-edit {
                padding-bottom: 0;
            }
        }
    }
}

.ui.table td.kpi-cell-secondary {
    text-align: center;
}

.company-statistics {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #E2E8EF;
    height: 120px;
    padding: 13px 19px;
    margin-bottom: 21px;

    display: flex;

    .ui.inverted.dimmer {
        background-color: rgba(255, 255, 255, 0.49) !important;
    }

    .segment {
        padding: 0 !important;
        width: 328px !important;
    }

    .ui.selection.dropdown {
        border: 1px solid #DEE5EC;
    }

    > div {
        width: 50%;

        &:first-child {
            padding-right: 10px;
        }
    }

    &__company-select {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > label {
            margin-bottom: 17px;
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: #4B5057;
        }

        .disabled {
            background: rgba(248, 248, 248, 0.54) !important;
        }

        .dropdown {
            min-height: 40px !important;

            .icon {
                padding: 13px 10px !important;
            }

            .text {
                margin-top: 3px;
                font-size: 14px;
            }
        }
    }

    &__statistics {
        display: flex;
        justify-content: space-between;
        padding: 0 1px 0 20px;
    }

    &:before {
        content: '';
        position: absolute;
        height: 30px;
        width: 194px;
        bottom: -30px;
        background-image: url("../../img/figure.svg");
        background-position: bottom;
    }

    &:after {
        content: '';
        position: absolute;
        height: 37px;
        width: 194px;
        top: -37px;
        right: 0;
        background-image: url("../../img/figure.svg");
        background-position: top;
    }
}

.info-icon {
    width: fit-content;
    cursor: pointer;

    &__circle {
        display: flex;
        justify-content: center;
        width: 64px;
        height: 64px;
        background: #E2E8EF;
        border-radius: 50px;

        > .icon {
            margin: auto;
            line-height: 24px;
        }
    }

    &__info-name {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        margin-top: 10px;
    }

    &:hover {
        .info-icon {
            &__circle {
                background: #E2E8EF;

                > .icon {
                    color: rgba(3, 78, 162, 1) !important;
                }
            }

            &__info-name {
                color: rgba(3, 78, 162, 1) !important;
            }
        }
    }

    &__popup {
        line-height: 18px;

        .popup-label {
            font-weight: 700;
            font-size: 14px;
            color: #475566;
            padding: 3px 0 !important;
        }

        .popup-data {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #475566;

            border-top: 1px solid #D3D3D3;
            margin: 10px 0 0 0;
            padding: 10px 0 0 0 !important;

            .color_blue {
                font-weight: 700;
                color: #034EA2 !important;
            }

            > div {
                display: flex;
                justify-content: space-between;

                > div {
                    flex: 1 0;
                    border-bottom: 1px dotted;
                    height: 1em;
                    margin: 1px;
                }
            }
        }

    }
}

.more-company-statistics {
    margin-bottom: 13px;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        h2 {
            margin-top: auto !important;
            margin-bottom: auto;
        }

        > div:last-child {
            display: flex;

            > label {
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 10px;
            }
        }
    }

    > div:not(:first-child), .segment > div {
        margin-bottom: 10px;
    }
}

.stat-data-blocks {
    display: flex;
    justify-content: space-between;
}

.styled-block-stat {
    background: #FFFFFF;
    border: 1px solid #E2E8EF;
    border-radius: 2px;
    margin-bottom: 20px;
    transform-style: preserve-3d;

    > div {
        transform: translateZ(1px);
    }

    &:hover {
        box-shadow: 0 3px 8px #D4DCE6;
        cursor: pointer;

        .statistic-block__icon .icon, .statistic-diagram__header .icon {
            color: rgba(3, 78, 162, 1) !important;
        }
    }

    &:not(:last-child) {
        margin-right: 20px;
    }
}

.statistic-block {
    position: relative;
    height: 130px;
    padding: 11px 19px;
    width: 33%;

    &_mini {
        height: 120px;
        padding-bottom: 15px;

        &:before {
            position: absolute;
            right: 0;
            bottom: 0;
            content: '';
            width: 110px;
            height: 62px;
            transform: rotate(-180deg) translateZ(1px);
            background-image: url("../../img/figure.svg");
            background-position: bottom right;
            background-size: 162px 125px;
        }
    }

    > div:first-child {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;

        h3 {
            margin-bottom: 10px;

            > .label-description {
                margin-top: 7px;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                text-transform: lowercase;
            }
        }
    }

    &__change-value {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        min-height: 14px;

        > span {
            font-weight: 400;
        }
    }

    &__change-icon {
        &.statistic-block {
            &_positive:before {
                content: '\25B2';
            }

            &_negative:before {
                content: '\25BC';
            }
        }
    }

    &__value {
        font-weight: 700;
        font-size: 24px;
        color: #000000;

        &_out-of {
            font-weight: 400;
            color: rgba(86, 86, 86, 1);
        }

        &_unit {
            font-weight: 400;
            font-size: 22px;
        }

        &_info {
            position: relative;

            > .icon {
                color: #4B5057 !important;
                position: absolute;
                top: -3px;

                &:hover {
                    color: #034EA2 !important;
                }
            }
        }
    }

    &__popup {
        color: white !important;
        background: #034EA2 !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 18px !important;
        border: 1px solid rgba(58, 132, 255, 1) !important;
        left: -7px !important;
        top: -3px !important;

        &:before {
            background: #034EA2 !important;
            box-shadow: 1px 1px 0 0 rgba(58, 132, 255, 1) !important;
        }
    }

    &__icon {
        position: absolute;
        right: 16px;
        top: 0;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &_positive {
        color: #81A700 !important;
    }

    &_negative {
        color: #FF0000 !important;
    }

    &_average {
        color: #F6BF3A !important;
    }

    &_neutral {

    }

    &_fix-styles {
        .info-icon {
            margin-top: 20px !important;
        }
        .statistic-block {
            &__value {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                margin-right: 80px;
            }
        }
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 82px;
            height: 67px;
            transform: rotate(0deg) translateZ(1px);
            background-image: url("../../img/figure.svg");
            background-position: top right;
            background-size: 162px 125px;
        }
    }
}

.statistic-diagram {
    position: relative;
    height: 300px;
    width: 50%;
    padding: 16px 18px;

    &:before {
        position: absolute;
        left: 16px;
        top: 114px;
        content: '';
        width: 149px;
        height: 81px;
        transform: translateZ(1px);
        background-image: url("../../img/figure.png");
        background-position: bottom right;
    }

    &__header {
        display: flex;

        > div:first-child {
            margin-right: 12px;
        }
    }
}

.doughnut-chart {
    position: absolute;
    top: -17px;
    width: 100%;

    &__diagram {
        position: relative;
        margin-right: 1%;
        margin-left: auto;
        width: 175px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__percent {
        position: absolute;
        font-weight: 700;
        font-size: 24px;
        color: #81A700;
        width: 100%;
        text-align: center;
    }

    &__legend {
        font-family: 'Ubuntu', sans-serif !important;
        font-size: 12px;
        color: #4B5057;

        > div {
            &:before {
                content: '\2B24';
                margin-right: 7px;
                font-size: 14px;
                line-height: 20px;
            }

            &:nth-child(1) {
                &:before {
                    color: #92E72B;
                }
            }

            &:nth-child(2) {
                &:before {
                    color: #FF0000;
                }
            }
        }
    }
}

.chart-tooltip {
    position: relative;
    font-family: 'Ubuntu', sans-serif !important;
    background-color: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4B5057;
    padding: 5px 10px;
    border: 1px solid #C4D6E9;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(119, 119, 119, 0.31);

    &:before {
        position: absolute;
        content: '';
        width: 0.71428571em;
        height: 0.71428571em;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
        left: -5px;
        top: 18px;
        border-left: 1px solid #C4D6E9;
        border-bottom: 1px solid #C4D6E9;
    }

    &_position_left {
        &:before {
            left: auto;
            right: -5px;
            top: 18px;
            transform: rotate(225deg);
        }
    }

    &_0 {
        border-color: #92E72B;

        &:before {
            border-color: #92E72B;
        }

        .chart-tooltip {
            &__label:before {
                color: #92E72B;
            }
        }
    }

    &_1 {
        border-color: #FF0000;

        &:before {
            border-color: #FF0000;
        }

        .chart-tooltip {
            &__label:before {
                color: #FF0000;
            }
        }
    }

    &_graph-1 {
        border-color: #92E72B;

        &:before {
            border-color: #92E72B;
        }
    }

    &_graph-0 {
        border-color: #034EA2;

        &:before {
            border-color: #034EA2;
        }
    }

    &__label {
        &:before {
            content: '\2B24';
            margin-right: 4px;
            font-size: 12px;
            line-height: 20px;
        }
    }
}

.select-years {
    padding-top: 6px;
    justify-content: end;

    .years__menu {
        right: 0;
        left: auto !important;
    }

    input {
        width: 100px;
    }
}

.years-graph {
    height: 326px;
    width: 100%;
    padding: 26px 14px 24px;

    > h4 {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 65%;
        width: 100%;
        text-align: center;
    }

    &__line {
        padding-top: 10px;
        height: 240px !important;
    }

    &__legend {
        position: absolute;
        top: 23px;
        right: 26px;

        > div {
            margin-left: 20px;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                left: -20px;
                top: 3px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #EDE9FD;
                border: 2px solid #92E72B;
            }
        }

        > div:nth-child(2) {
            &::before {
                background: #BBDADD;
                border-color: #034EA2;
            }
        }
    }
}

.company-stat-table {
    width: 100%;
    height: 495px;
    padding-top: 10px;

    &__sort-btns {
        margin-bottom: auto;
        margin-top: 3px;

        > div {
            position: relative;
            height: 5px;
            width: 6px;
            margin-left: 5px;
            cursor: pointer;

            &:hover, &.active {
                &:before {
                    color: #034EA2;
                }
            }

            &:before {
                position: absolute;
                font-family: 'Icons', serif;
                content: '\f0d7';
                font-size: 10px;
                line-height: 0;
                height: 3px;
                width: 6px;
                top: 2px;
            }

            &:first-child {
                margin-bottom: 1px;

                &:before {
                    content: '\f0d8';
                }
            }
        }
    }

    &__header-cell {
        cursor: pointer !important;
        display: flex;
    }

    .table {
        margin-top: 0 !important;
        border-color: #E2E8EF !important;

        &:hover {
            box-shadow: 0 3px 8px #D4DCE6;
            cursor: pointer;
        }

        tbody {
            tr {
                &:nth-child(2n) {
                    background-color: #F8F8FA !important;
                }

                &:last-child {
                    border-bottom-left-radius: 3px !important;

                    td:first-child {
                        border-bottom-left-radius: 3px !important;
                        border-bottom-right-radius: 3px !important;
                    }

                    td:last-child {
                        border-bottom-right-radius: 3px !important;
                    }
                }

                &:hover {
                    position: relative;

                    &:after {
                        z-index: 22;
                        position: absolute;
                        top: -2px;
                        left: -12px;
                        background-color: rgba(255, 255, 255, 0);
                        content: '';
                        width: calc(100% + 24px);
                        height: 38px;
                        outline: 1px solid rgba(58, 132, 255, 0.3);
                        border: 4px solid white;
                        border-left: 14px solid white;
                        border-right: 14px solid white;
                    }

                    &:nth-child(2n) {
                        &:after {
                            border-color: #F8F8FA;
                        }
                    }
                }
            }
        }

        thead {
            th {
                &:first-child {
                    border-top-left-radius: 2px !important;
                }

                &:last-child {
                    border-top-right-radius: 2px !important;
                    > div {
                        display: flex;
                        justify-content: center;
                    }
                }

                height: 42px;

                font-weight: 500 !important;
                font-size: 13px;
                line-height: 15px;
                color: #4B5057 !important;

                border-radius: 0 !important;
                border-bottom: 0 !important;
                background: #E2E8EF !important;
            }
        }

        tr td {
            height: 34px;
            padding: 2px .78571429em;

            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;

            border-bottom: 0 !important;
        }
    }

    > .menu {
        font-family: 'Ubuntu', sans-serif !important;
        box-shadow: none !important;
        border: none !important;
        min-height: auto !important;

        .item {
            height: 38px;
            width: 80px;
            font-size: 14px;
            color: #475566 !important;
            border: 1px solid #E2E8EF !important;
            border-radius: 0 !important;
            display: flex;
            justify-content: center;

            &:before {
                content: none !important;
            }
        }

        .active {
            background-color: #034EA2 !important;
            border-color: #034EA2 !important;
            color: white !important;
        }
    }
}

.lates {
    .cell-grid {
        justify-content: center;
    }
}

.files-view {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    .ui.inverted.dimmer {
        background-color: rgba(245, 247, 250, 0.61) !important;
    }
}

.file-view {
    > div {
        padding: 15px;
    }
    .description {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    width: 325px !important;
    margin: 15px !important;

    &__icon {
        i {
            font-size: 70px !important;
            padding-top: 24px;
            padding-left: 4px;
            color: #cccccc !important;
        }
        cursor: pointer;
    }
}

.menu-icon-sui:before {
    position: absolute;
    top: 7px;
    left: 11px;
}