body {
    overflow-x: auto;
    overflow-y: hidden;
    min-width: 1280px !important;
    font-size: 13px;
    background: #F5F7FA;
    font-family: 'Ubuntu', sans-serif !important;
    color: #4d4d4d !important;
}

i.blue.icon {
    color: #18a8cc !important;
}

.margin-top-8 {
    margin-top: 8px !important;
}

.margin-bottom-4 {
    margin-bottom: 4px !important;
}

.margin-top-auto {
    margin-top: auto !important;
}

.margin-bottom-10 {
     margin-bottom: 10px !important;
}

.margin-bottom-24 {
    margin-bottom: 24px !important;
}

.margin-left-8 {
    margin-left: 8px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-0 {
    padding: 0 !important;
}

.d-flex {
    display: flex !important;
}

.j-c-end {
    justify-content: flex-end !important;
}

.w-100 {
   width: 100% !important;
}

.text-nowrap {
    white-space: nowrap;
}

.t-a-center {
    text-align: center !important;
    input {
        text-align: center !important;
    }
}

.container {
    padding: 70px 15px 0 15px;
    position: relative;
}

.Toastify__toast {
    position: relative;
    min-height: 1em;
    margin: 1em 0;
    background: #f8f8f9;
    padding: 0.7em 1em 0.7em 1.5em !important;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
        -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
        -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease,
        -webkit-box-shadow 0.1s ease;
    border-radius: 0.28571429rem !important;
    -webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
}

.Toastify__toast--info {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
    background-color: #f8ffff !important;
    color: #276f86 !important;
}

.Toastify__toast--error {
    -webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
    background-color: #fff6f6 !important;
    color: #9f3a38 !important;
}

.Toastify__toast--warning {
    -webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
    background-color: #fffaf3 !important;
    color: #573a08 !important;
}

.Toastify__close-button {
    color: #1b1c1d !important;
}

.ui.fullscreen.modal,
.ui.fullscreen.scrolling.modal {
    left: 2.5% !important;
}

.scrolling.content {
    max-height: 76vh !important;
    overflow: auto;
}

.card-modal {
    min-height: 92vh;

    .content {
        padding-bottom: 15px !important;
        border-bottom-left-radius: 0.28571429rem;
        border-bottom-right-radius: 0.28571429rem;
    }

    .actions {
        min-height: 65px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .ui.negative.message {
        position: absolute;
        bottom: 64px;
        width: 100%;
    }
}

.sub_modal {
    min-height: 81vh;
}

.ui.dimmer {
    justify-content: center !important;
}


@media only screen and (min-width: 1500px) {
    .ui.wider.container {
        width: calc(1400px) !important;
    }
}

.container-margin-top-bottom {
    margin-bottom: 70px;
    position: relative;

    &_top15 {
        margin-top: 15px;
    }
}

.padding-14 {
    padding: 14px !important;
}

.card-columns {
    padding: 8px 0;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

.shippers-table {
    .ui.celled.table tr td:first-child,
    .ui.celled.table tr th:first-child {
        border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
    }
}

.ui.form label {
    margin-bottom: 8px;
    font-weight: bold;
}

.table-loader {
    margin-top: 58px !important;
    left: 50%;
    top: calc(100vh / 2) !important;
    &-big {
        height: calc(100vh - 180px) !important;
        .ui.loader {
            position: fixed !important;
        }
    }
}

.table-bottom-loader {
    position: sticky;
    left: calc(100% / 2);
    bottom: 24px;
}

.ui.checkbox label {
    font-weight: normal !important;
}

.ui.multiple.dropdown > .label {
    font-size: 0.85714286rem !important;
}

.dictionary-edit {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 24px 32px;
    width: 100% !important;

    >.field:not(.wide):not(.code-field) {
        width: 33.3% !important;
        transition: width 0ms;
    }

    >.field.wide {
        width: 100% !important;
    }

    >.field {
        margin-bottom: 15px !important;
        padding: 0 15px !important;
        min-height: 79px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    >.field input[type='text'] {
        width: 100% !important;
    }

    /*.dropdown {
        transition: width 0ms!important;
    }

    .visible {
        position: sticky !important;
        width: calc(33.3% - 45px) !important;
        transition: width 0ms;
        //top: calc();
    }*/
}

@media screen and (max-width: 600px) {
    body {
        min-width: inherit !important;
        background: inherit;
        overflow-y: auto;
    }

    .card-header-panel, .duty-shift-table .table thead {
        display: none !important;
    }

    .card-content {
        padding: 10px 0 60px 0;

        > div {
            width: 100%;
        }

        .vertical.menu,
        .block-item-securityCases,
        .block-item-shippingAnomalies,
        .block-item-deliveryDocuments,
        .block-item-fines,
        .stopper-anomaly {
            display: none;
        }

        .card-content-block {
            width: auto;
            min-width: auto;
            margin: 0 10px 20px 10px;

            .field {
                display: flex;
                gap: 6px;

                .ui.disabled {
                    padding: 0;
                    border: none !important;
                    background: none !important;
                    line-height: normal;
                    min-height: auto;

                    .icon.clear {
                        display: none;
                    }
                }
            }

            .tabs-card .ui.grid > .row > .column.column {
                width: 100% !important;
            }

            .tabs-card > .segment {
                padding: 0;

                .row > .column, .row > .column > .segment {
                    padding: 0 !important;
                }

                .tabs-card {
                    padding: 0;

                    .file-item {
                        width: 100%;

                        .image-container {
                            height: 100% !important;
                            width: 80px;
                        }

                        .file-info {
                            padding-left: 0;
                        }

                        .file-item-actions {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: space-around;
                            align-items: center;
                            position: absolute;
                            height: 100%;
                            right: 0;
                            border-left: 1px solid #d8d8d8;
                            padding: 8px;

                            i.icon {
                                display: block !important;
                                position: initial;
                            }
                        }

                        .uploaded-image-add-button {
                            width: 100%;
                            height: 100%;

                            cursor: pointer;

                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .card-content-block_menu {
            width: 100%;
        }

        .block-item-additionalData, .block-item-history {
            display: none;
        }

        .block-item-documentWithTemplates {
            .ui.segment {
                border: none;
                box-shadow: none;
            }
        }
    }

    .dictionary-edit {
        padding: 0;

        > .field.field.field.field {
            width: -webkit-fill-available !important;
        }
    }
}

.user-form,
.role-form,
.profile-form {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.profileCard {
    padding-bottom: 12px;
    .ui.segment {
        margin: 0 !important;
        height: 100%;
    }
}

.tabs-card {
    padding: 24px 32px;

    .ui.grid > .row:not(:last-child) {
        padding-bottom: 0 !important;
    }

    .ui.grid > .row > .column {
        //display: flex;
        // align-items: flex-end;
        .field {
            width: 100%;
        }
    }

    .ui.segment {
        margin: 0 !important;
        height: 100%;
    }

    .all-tabs > .ui.menu.tabular {
        white-space: nowrap;
    }

    .ui.checkbox {
        padding: 4px 0;
    }

    &_history {
        max-height: 500px;
        overflow: auto;

        .ui.grid > .row:nth-child(2n) {
            background: #f9f9f9;
        }
    }
}

.ext-tabs-card {
    height: 52vh;
    overflow: auto;
}

.add-right-elements {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-end;

    button {
        height: 33px;
    }
}

.mini-column {
    .field {
        width: 50% !important;
    }
}

.vertical-menu-card {
    display: flex;

    .ui.vertical.menu {
        margin: 0 12px 0 0 !important;
        flex-grow: 0;
        /*  width: max-content;*/
        /*height: max-content;*/
    }

    .shipping-card-content {
        width: 100%;
    }
}

.all-tabs {
    .ui.vertical.menu {
        margin: 0 12px 0 0 !important;
    }
}

.temperature-fields {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 36px;

    label {
        font-size: 0.92857143em;
        color: #4d4d4d !important;
        font-weight: 500 !important;
    }

    .field {
        width: 30% !important;
    }
}

.grid-card-actions {
    display: flex;
    justify-content: space-between;
}

.grid-card-header {
    display: flex;
    align-items: center;

    > div:last-child {
        margin-left: 12px;
    }
}

.history-who {
    font-size: 12px;
    font-weight: 500;
    word-break: break-word;
    color: #4d4d4d;
}

.history-comment {
    white-space: pre-wrap;
    word-break: break-all;
}

.table-edit-field-nart {
    input {
        width: 150px;
    }
}
.table-edit-field-quantity {
    input {
        width: 80px;
    }
}

.report {
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;

    &_table-data {
        border: 0 !important;
    }

    &__pagination {
        border-top: 1px solid #e6e6e6 !important;
        display: flex;
        justify-content: space-between;
    }

    &__pagination-select{
        margin-left: 10px;
        min-width: 80px !important;
    }

    &_params {
        width: 100%;
        background-color: white;
        border: 1px solid #e6e6e6;
        padding: 15px 24px 24px 24px;

        &__btn {
            position: absolute;
            right: 35px;
            left: auto;
            z-index: 5;
        }

        .card-content-block_header {
            padding: 0;
            border-bottom: 0;
            justify-content: flex-end;
            z-index: 3;
        }
    }

    &_table {
        margin-top: 12px;

        .card-content-block_open{
            overflow: auto !important;
            position: static;
        }
        &_max {
            .card-content-block_open{

            }
        }
        .card-content-block {
            margin: 0!important;
            width: auto !important;
        }
        .card-content-block_header {
            position: relative;
        }
    }

    &_button {
        margin-top: 22px !important;
        width: 100%;
    }

    &__table-info {
        .scroll-table-container {
            height: 100%;
            border: 0;
        }
    }
}

input::-webkit-calendar-picker-indicator {
    //display: none;
}

.react-datepicker__input-time-container {
    display: flex;
    align-items: center;
}

.dnd-not-visible {
    display: none;
}

.representation-modal {
    .ui.message {
        margin: 0 !important;
    }
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
    opacity: 1 !important;
}

.ui.disabled.search,
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
    opacity: 0.8 !important;
    background: rgba(1, 1, 1, 0.05);
}

.table-action-buttons {
    white-space: nowrap;
}

.change_password {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ui .label {
    font-weight: 500 !important;
    color: #4d4d4d;
}

.ui.modal {
    .header {
        font-family: 'Ubuntu' !important;
        font-weight: 500 !important;
        color: #4d4d4d !important;
    }
}

.unblockScroll {
    cursor: default !important;
    pointer-events: all !important;
}

/*
.ui.form input:focus,
.ui.form textarea:focus {
    //box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    border-color: #18a8cc!important;
    //border: none !important;
}

.ui.input,
.ui.dropdown > input,
.react-datepicker__input-container {
    //border: none !important;
    border-radius: 4px !important;
    !* box-shadow: inset 0 0 0 1px #b3b3b3 !important;*!
}

.ui.input.focus > input,
.ui.input > input:focus,
.ui.dropdown.active {
    //box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    border-color: #18a8cc!important;
    //border: none !important;
}


.ui.selection.active.dropdown .menu {
    box-shadow: inset 0 0 0 1px #b3b3b3, 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    border: none !important;
}*/

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #18a8cc !important;
}

/*.react-datepicker-wrapper, .react-datepicker__input-container {
    &:focus {
        outline: none;
    }

    &:focus input {
        box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2)!important;
        border: none!important;
    }
}*/

.ui.button {
    font-family: 'Ubuntu' !important;
    font-weight: 500 !important;
}

.ui.button.blue,
.ui.primary.button,
.ui.blue.label {
    background-color: #18a8cc !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #18a8cc !important;
}

i.red.icon {
    color: #9a0000 !important;
}

.ui.red.button,
.ui.red.label {
    background-color: #9a0000 !important;
}

.ui.inverted.red.basic.button:hover,
.ui.inverted.red.basic.buttons .button:hover,
.ui.inverted.red.buttons .basic.button:hover {
    box-shadow: 0 0 0 2px #9a0000 inset !important;
    color: #9a0000 !important;
}

i.green.icon {
    color: #1f5d39 !important;
}

i.light-green.icon {
    color: #35af43 !important;
}

.ui.green.button,
.ui.green.label {
    background-color: #1f5d39 !important;
}

.ui.inverted.green.button,
.ui.inverted.green.buttons .button {
    color: #35af43 !important;
    box-shadow: 0 0 0 2px #35af43 inset !important;
}

i.orange.icon {
    color: #e98b0d !important;
}

.ui.orange.button,
.ui.orange.label {
    background-color: #e98b0d !important;
}

i.purple.icon {
    color: #463082 !important;
}

.ui.purple.button,
.ui.purple.label {
    background-color: #463082 !important;
}

i.olive.icon {
    color: #7b8e4a !important;
}

.ui.olive.button,
.ui.olive.label {
    background-color: #7b8e4a !important;
}

i.teal.icon {
    color: #3c948e !important;
}

.ui.teal.button,
.ui.teal.label {
    background-color: #3c948e !important;
}

i.pink.icon {
    color: #f3a0a6 !important;
}

.ui.pink.button,
.ui.pink.label {
    background-color: #f3a0a6 !important;
}

.template_link {
    color: #18a8cc;
    cursor: pointer;
}

.vehicle_row{
    margin-bottom: 15px;
}

.vehicle_grid{
    margin: 0 !important;
}

.textarea_carrier{
    min-width: 66.6% !important;
}

.line-break {
    width: 20%;
    visibility: hidden;
}

.docs-download_link{
    cursor: pointer;
}

.ui.multiple.search.dropdown.disabled>input.search {
    width: 0 !important;
}

.textarea-disabled-scroll {
    pointer-events: auto !important;
}

.banners-card {
    &__form {
        padding: 30px;
    }

    &__input {
        display: flex;
        align-items: center;
        width: 100% !important;

    }

    &__fields {
        padding-top: 20px;
    }

    &__input-lenght {
        padding: 10px;
        width: 55px;
    }
}

.stopper-anomaly {
    min-width: 900px;
    margin-left: -250px !important;
}

.photo-anomaly {
    position: relative;
    display: inline-block;

    width: 210px;
    height: 210px;

    &__img {
        margin: 10px;
        width: 190px;
    }

    &__no-photo {
        margin: 0 20px 20px 10px;
    }

    &__delete-btn {
        z-index: 5;
        position: absolute !important;
        right: 7px !important;
        top: 10px !important;
        background-color: rgba(0,0,0,0) !important;
        &:disabled {
            visibility: hidden;
        }
    }

    &__block {
        padding-bottom: 10px;

        h5 {
            margin-left: 10px;
            margin-bottom: 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 15px;
        }
    }

    &__point {
        h3 {
            margin-left: 10px;
            margin-bottom: 10px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 2px;
        }

        &:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid #e6e6e6;
        }
    }
}

.input-sizer {
    visibility: hidden !important;
    height: 0;
    padding-left: 26px;
    white-space: nowrap;
}

.anomaly-info-text {
    color: gray;
    font-size: 14px;
    margin-left: 7px;
}

.container__report {
    margin-top: 10px !important;
}
.ui.grid>.row>.column.col-w-2 {
    width: 40% !important;
}

.input-modal {
    width: 100%;
    padding: 12px;
}

.error {
    .checkbox {
        color: #9f3a38;
    }
    a {
        color: #862e2d;
    }
}

.fields-bottom {
    > .column {
        margin-top: auto !important;
        margin-bottom: 0 !important;
    }
}

.download-loader {
    height: 70vh;
    > .dimmer {
        background-color: rgba(0, 0, 0, 0) !important;
    }

}

.ui.form .payment-term-modal-cost-row .field .ui.input input { 
    font-size: 1.05em;
    font-weight: bold;
}

.ui.small.modal>.header.payment-term-modal-header:not(.ui) {
    font-size: 1.45em;
}

.ui.checkbox.payment-terms-rules {
    font-size: 1.1rem;
}

.form-group-btn {
    display: flex;
    align-items: center;

    .field {
        flex: auto;
        padding-right: 16px;
    }

    button {
        margin-top: .28571429rem!important;
    }
}

.code-field {
    width: 100% !important;
}

.color__black {
    color: black !important;
}

.table-edit-fields {
    padding: 5px !important;
    .input, .field {
        border: 0 !important;
    }
    .input {
        width: 100%;
    }
}

.table-modal {
    .ui.celled.table tr td, .ui.celled.table tr th {
        border-left: 0 !important;
        border-radius: 0 !important;
    }
    .ui.celled.table tr td:last-child, .ui.celled.table tr th:last-child {
        border-right: 0 !important;
    }
    .table {
        border-top: 0 !important;
        border-collapse: separate !important;
        border-radius: 0 !important;
    }

    max-height: 50vh;
    overflow: auto;
}

.doc-btn {
    display: contents;
}

.task-table {
    border: 0 !important;
    &__block {
        border: 1px solid rgba(34,36,38,.15) !important;
        border-radius: 4px;
    }
    th {
        background-color: white !important;
    }
    .field input {
        max-width: 165px;
    }

    .custom-checkbox .icon {
        margin: 0;
    }
}