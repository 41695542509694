.ext-header-row {
    th {
        position: sticky;
        top: 43px;
        min-width: 150px;
    }
}

.status-label-bottom {
    vertical-align: bottom !important;
}

.column-header {
    text-align: center !important;
    width: 100% !important;
    background-color: #f9fafb !important;
    &__status{
        border-top: 1px solid rgba(34,36,38,.1) !important;
    }
}

.ui.table thead th.column-header__statuses {
    border-bottom: 0 !important;
}
