.params-notifications {
    overflow: hidden;
    .card-content-block {
        width: 100%;
        height: calc(100vh - 250px) !important;
        overflow-x: auto;
    }
    &__block {
        margin: 0 !important;
        padding: 24px !important;

        &_fixed {
            position: sticky;
            left: 0;
        }

        .checkbox {
            padding: 10px;
        }
    }

    &__grid{
        flex-grow: 2;
        overflow-y: auto;
        overflow-x: auto;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    &__table {
        padding: 20px !important;
        padding-top: 0!important;
        border: 0 !important;

        tr, th {
            border-radius: 0 !important;
            border-left: 1px solid rgba(34,36,38,.1) !important;
            border-top: 1px solid rgba(34,36,38,.1) !important;
        }
        th:last-child{
            border-right: 1px solid rgba(34,36,38,.1) !important;
        }
        td:last-child{
            border-right: 1px solid rgba(34,36,38,.1) !important;
        }
        tr:last-child{
            td {
                border-bottom: 1px solid rgba(34,36,38,.1) !important;
            }

        }
        td:first-child{
            border-left: 1px solid rgba(34,36,38,.1) !important;
        }
    }

    &__table-header {
        border: none !important;
    }

    &__checkboxs{
        padding-bottom: 24px;
    }

    &__actions{
        display: flex;

        .icon {
            margin: 5px;
        }
    }

    &__col {
        &_min {
            min-width: 150px;
        }
        min-width: 250px;
    }
}

.selection-field {

    &__slider {
        padding-bottom: 5px;
        padding-top: 2px;
        label{
            vertical-align: top !important;
        }
    }

    &__label{
        padding-right: 13px;
        cursor: pointer;
        color: rgba(0,0,0,.4) !important;
        &_right{
            padding-left: 13px;
        }
        &_opacity{
            color: rgba(0,0,0,.95) !important;
        }
    }

    &_no-select {
        min-width: auto !important;
    }
}