.ui.menu {
    border-radius: 0 !important;
}
.fixed .item {
    height: 100%;
    font-weight: 500 !important;
}

header {
    > .ui.secondary.pointing.menu {
        .item {
            margin: 0 !important;
        }

        > .item:first-child {
            width: 100px;
            border-right: 1px solid #3A84FF;
            border-bottom: 1px solid #3A84FF;
        }
    }
    .ui.secondary.menu .dropdown.item >.menu {
        max-height: calc(100vh - 70px);
        overflow-y: auto;
    }
}

.ui.secondary.pointing.menu {
    height: 60px;
    background: #034EA2;
    border-bottom: 0 !important;
    color: white !important;
    font-family: 'Ubuntu', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    transition: none !important;

    .item {
        color: white !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.06) !important;
        }
    }

    .active {
        border-color: #18a8cc !important;
        font-weight: 500 !important;
    }
}

.ui.secondary.menu .item > img:not(.ui) {
    width: 8em !important;
}

.ui.secondary.menu .dropdown.item > .menu,
.ui.text.menu .dropdown.item > .menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
}

.ui.secondary.pointing.menu .item .active.item {
    border-color: transparent !important;
}

.ui.secondary.pointing.menu .active.item.dropdown {
    font-weight: initial;
}

.ui.secondary.pointing.menu .item.dropdown.superActive {
    border-color: #18a8cc !important;
    //border-color: #1b1c1d;
}

.ui.secondary.menu .header.item {
    padding: 0.5em 1.5em !important;
}

.header-support {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    > div {
        height: 100%;
    }

    i.icon {
      margin: 0 .25rem 5px 0;
    }

    &_contacts {
        margin-left: 12px;
        padding-right: 12px;
        border-right: 1px solid rgba(255, 255, 255, 0.6);

        > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        a {
            color: #18a8cc;

            &:hover {
            }
        }
    }

    &.login-page {
        width: auto;
        color: white;
        .header-support {
            &_contacts {
                border-right: 0;
                width: 264px;
                a {
                    color: #2fa2bf;

                    &:hover {
                    }
                }
            }
        }
    }
}

.header-menu-wrap {
    white-space: break-spaces !important;
}

.ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
    display: flex!important;
}

#b-color__blue {
    background-color: #00a2e8 !important;
}
